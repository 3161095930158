import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

// Elements
import BannerDetail from 'components/elements/Misc/BannerDetail'
import Share from 'components/elements/Misc/Share'
import FormVacancy from 'components/elements/Misc/FormVacancy'
import ParseContent from 'components/shared/ParseContent'

const Section = styled.section`
  & ul {
    list-style-type: none;
    & li {
      position: relative;
    }
  }
`

const BulletPoints = styled.div`
  & ul {
    margin-left: 0.25rem;
    & li {
      font-family: ${({ theme }) => theme.font.family.primaryMedium};
      &:before {
        position: absolute;
        left: -30px;
        top: 5px;
        content: '';
        width: 14px;
        height: 14px;
        background-color: ${({ theme }) => theme.color.primary};
      }
    }
  }
`

const Content = styled(ParseContent)`
  & ul {
    & li {
      &:before {
        position: absolute;
        left: -30px;
        top: 8px;
        content: '';
        width: 9px;
        height: 9px;
        background-color: ${({ theme }) => theme.color.primary};
      }
    }
  }
`

const SideWrapper = styled.div`
  position: relative;
  @media (min-width: 992px) {
    margin-top: -9rem;
  }
`

interface PageProps {
  data: {
    page: {
      title?: string
      vacancydetail: any
      path?: string
      seo: any
      flexContent: any
    }
  }
  location?: any
  prefix?: string
  pageContext?: any
}

const PageTemplate: React.FC<PageProps> = ({
  data: {
    page: { title, vacancydetail, flexContent, seo },
  },
  location = {},
  prefix = 'Vacancy_Flexcontent',
  pageContext,
}) => (
  <Layout>
    <SEO seo={seo} />
    <BannerDetail
      type="default"
      pageContext={pageContext}
      title={title || 'Vacature'}
      image={vacancydetail?.bannerimage}
    />
    <Section className="mb-5 pb-lg-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="row">
              <div className="col-lg-8">
                <BulletPoints>
                  <ul className="row justify-content-between mb-5">
                    {vacancydetail?.bulletpoints.map(
                      (item: any, index: number) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={index} className="col-sm-5 mb-4 px-lg-0">
                          {item.bulletpoint}
                        </li>
                      )
                    )}
                  </ul>
                </BulletPoints>
                <div className="row">
                  <ParseContent
                    content={vacancydetail?.shortdescription}
                    className="mb-5 pe-lg-5"
                  />
                  <Content
                    content={vacancydetail?.description}
                    className="mb-5 mb-lg-0 pe-lg-5"
                  />
                </div>
              </div>
              <SideWrapper className="col-lg-4">
                <Share
                  heading="Deel deze vacature"
                  location={location}
                  subject={title || 'Vacature'}
                  body={vacancydetail?.shortdescription}
                />
                <FormVacancy className="mt-5" />
              </SideWrapper>
            </div>
          </div>
        </div>
      </div>
    </Section>

    <FlexContentHandler
      prefix={prefix}
      fields={flexContent}
      location={location}
      pageContext={pageContext}
    />
  </Layout>
)

export const pageQuery = graphql`
  query vacancyById($pageId: String!) {
    page: wpVacancy(id: { eq: $pageId }) {
      ...generalVacancyFragment
    }
  }
`

export default PageTemplate
