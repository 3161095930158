import React from 'react'
import styled from 'styled-components'

// Elements
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Components
import FormDuuf from 'components/related/Form'

const FormWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: ${({ theme }) => theme.font.family.primaryMedium};
    color: ${({ theme }) => theme.color.light};
    @media (min-width: 992px) {
      font-size: 28px;
    }
  }
`

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    position: relative;
    margin-bottom: 0.75rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: hsl(0, 0%, 100%);
    padding: 10px 15px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    position: relative;
    width: 100%;
    border-color: hsl(0, 0%, 100%);
    background-color: hsl(0, 0%, 100%);
    padding: 10px 15px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;

    & button {
      position: absolute;
      top: 6px;
      right: 8px;
      min-width: 120px;
      min-height: 24px;
      font-size: 15px;
      background-color: #000;
      &:before {
        content: 'Bestand kiezen';
        color: ${({ theme }) => theme.color.light};
        font-size: 13px;
        top: 2px;
      }
    }

    &:before {
      position: absolute;
      top: 6px;
      content: 'Upload je CV';
      opacity: 0.66;
    }
  }

  & .form-duuf-file-name {
    padding-left: 15px;
    position: absolute;
    background: white;
    width: 45%;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    position: absolute;
    top: 5px;
    right: 8px;
    color: tomato;
    opacity: 0.5;
    font-size: 10px;
  }

  & .form-duuf-submit {
    width: 100%;
    ${ButtonSecondaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.light};
  }

  & .form-duuf-something-wrong {
    color: ${({ theme }) => theme.color.light};
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: ${({ theme }) => theme.color.primary};
  }
`

interface FormVacancyProps {
  className?: string
}

const FormVacancy: React.FC<FormVacancyProps> = ({ className = '' }) => (
  <div className={className} id="form-vacancy">
    <FormWrapper className="px-xl-5 px-4 py-4">
      <h2 className="mb-4">Solliciteren?</h2>
      <StyledFormDuuf id={2} />
    </FormWrapper>
  </div>
)

export default FormVacancy
